// Settings types
export const settingsTypes = {
    TOGGLE_SOURCE_TITLE: 'TOGGLE_SOURCE_TITLE',
    TOGGLE_EDIT_TITLE: 'TOGGLE_EDIT_TITLE',
    CHANGE_TITLE_FONT_SIZE: 'CHANGE_TITLE_FONT_SIZE',
    CHANGE_EDIT_FONT_SIZE: 'CHANGE_EDIT_FONT_SIZE',
    CHANGE_ROW_LENGTH_VALUE: 'CHANGE_ROW_LENGTH_VALUE',
    CHANGE_READING_SPEED: 'CHANGE_READING_SPEED',
    TOGGLE_SPEECH_TO_TEXT: 'TOGGLE_SPEECH_TO_TEXT',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    CHANGE_TRANSLATE_LANGUAGE: 'CHANGE_TRANSLATE_LANGUAGE',
    CHANGE_SITE_LANGUAGE: 'CHANGE_SITE_LANGUAGE',
    TOGGLE_PUNCTATION: 'TOGGLE_PUNCTATION',
    TOGGLE_SPELLCHECKER: 'TOGGLE_SPELLCHECKER',
    TOGGLE_GVT_INSTANT: 'TOGGLE_GVT_INSTANT',
    TOGGLE_PUSH_HOLD: 'TOGGLE_PUSH_HOLD',
    AUTO_SAVE: 'AUTO_SAVE',
    HIGHLIGHT_PHRASE: 'HIGHLIGHT_PHRASE',
    HIGHLIGHT_PROBLEMATIC_WORDS: 'HIGHLIGHT_PROBLEMATIC_WORDS',
    KEYBOARD_POP: 'KEYBOARD_POP',
    CHANGE_THEME: 'CHANGE_THEME',
    TOGGLE_AUTO_RECORD: 'TOGGLE_AUTO_RECORD',
    TOGGLE_SORT: 'TOGGLE_SORT',
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR'
}

// Subtitle types
export const SET_TRANSLATION_SUBTITLE = 'SET_TRANSLATION_SUBTITLE'
export const SET_CHOSEN_SUBTITLE = 'SET_CHOSEN_SUBTITLE'
export const SET_UPLOADED_SUBTITLES = 'SET_UPLOADED_SUBTITLES'
export const SET_SUBTITLE_PROPERTIES = 'SET_SUBTITLE_PROPERTIES'
export const CLEAR_SUBTITLE_PROPERTIES = 'CLEAR_SUBTITLE_PROPERTIES'
export const SET_INITIAL_TRANSLATED_SUBTITLES = 'SET_INITIAL_TRANSLATED_SUBTITLES'
export const TRANSLATED_SUBTITLE = "TRANSLATED_SUBTITLE"
export const SET_TRANSLATED_SUBTITLES = "SET_TRANSLATED_SUBTITLES"
export const SET_LAST_EDITED = 'SET_LAST_EDITED'
export const SET_CURRENTLY_TRANSCRIBING = 'SET_CURRENTLY_TRANSCRIBING'
export const SET_LAST_EDITED_VALUE = 'SET_LAST_EDITED_VALUE'
export const SET_FIRST_SENTENCE = 'SET_FIRST_SENTENCE'
export const CHANGE_IMUTABLY = 'CHANGE_IMUTABLY'
export const SET_CAPITAL_LETTER = 'SET_CAPITAL_LETTER'
export const ADD_COMMENT = 'ADD_COMMENT'
export const STORE_COMMENT_ID = "STORE_COMMENT_ID"
export const SET_LAST_EDITED_COMMENT_ID = "SET_LAST_EDITED_COMMENT_ID"
export const SET_LAST_EDITED_COMMENT_VALUE = "SET_LAST_EDITED_COMMENT_VALUE"
export const ADD_COMMENT_UPLOADED = 'ADD_COMMENT_UPLOADED'
export const SET_SELECTION_START = "SET_SELECTION_START"
export const SET_SELECTION_END = "SET_SELECTION_END"
export const SET_CAPS_STATUS = "SET_CAPS_STATUS"

// User settings types
export const userSettings = {
    SET_VIDEO_PLAY_PAUSE_KEY: 'SET_VIDEO_PLAY_PAUSE_KEY',
    SET_NEXT_SUBTITLE_KEY: 'SET_NEXT_SUBTITLE_KEY',
    SET_PREVIOUS_SUBTITLE_KEY: 'SET_PREVIOUS_SUBTITLE_KEY',
    SET_MICROPHONE_KEY: 'SET_MICROPHONE_KEY',
    SET_LISTENING_FOR_KEY: 'SET_LISTENING_FOR_KEY',
    SET_USER_SETTINGS: 'SET_USER_SETTINGS'
}

// Error types
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

// Dictionary and spell checker types
export const SPELL_CHECK_SENTENCE = 'SPELL_CHECK_SENTENCE'
export const SPELL_CHECK_WRONG_WORDS = 'SPELL_CHECK_WRONG_WORDS'
export const ADD_WORD_TO_DICTIONARY = 'ADD_WORD_TO_DICTIONARY'

// Auth types
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const REGISTER_NEW_USER = 'REGISTER_NEW_USER'

// Gvt types
export const ADD_PHRASE = 'ADD_PHRASE'
export const GET_PHRASES = 'GET_PHRASES'
export const SET_PHRASES = 'SET_PHRASES'
export const SET_ALL_PHRASES = 'SET_ALL_PHRASES'
export const SET_DICT_WORDS = 'SET_DICT_WORDS'
export const UPLOAD_GVT_KEY = 'UPLOAD_GVT_KEY'

// Projects types
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS'
export const CLEAR_USER_PROJECTS = 'CLEAR_USER_PROJECTS'