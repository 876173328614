import React, { useEffect, useState, useRef, memo } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { ProgressBar } from 'react-bootstrap'
import './subtitleRow.css'
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { setChosenSubtitle } from '../../../actions/chosenSubtitleActions'
import { setSelectionEnd, setSelectionStart, storeCommentId, updateTranslatedSubtitles } from '../../../actions/translatedSubtitlesActions'
import { HighlightWithinTextarea } from 'react-highlight-within-textarea'
import { setCapitalLetter, setLastEdited, setLastEditedCommentValue, setLastEditedValue } from '../../../actions/subtitlePropertiesActions'
import { saveProcess } from '../../../actions/savingActions'
import { shouldHaveCapitalLetter, capitalLettersForNamesInPhrases, capitalizeByCaseStatus } from '../../../helpers/SentenceHelper'
import { isMobile } from 'react-device-detect';
import { setFirstSentence } from '../../../actions/subtitlePropertiesActions'

import { addCommentToSubtitle } from '../../../actions/translatedSubtitlesActions'
import { translateText } from '../../../actions/translateActions'
import { spellcheck } from '../../../actions/spellcheckActions'

let topPos = 0;
let leftPos = 0;

const TranslatedSubtitleCell = memo(function TranslatedSubtitleCell({
    translatedSubId,
    translatedSubText,
    translatedSubStart,
    translatedSubEnd,
    numberOfSubtitles,
    uploadedSubText,
    subtitleComment,
    onShouldCapitalize
}) {
    const dispatch = useDispatch()
    const store = useStore()
    const refDiv = useRef(null)
    const [highlightPhrases, setHighlightPhrases] = useState([])
    const [spellCheckWords, setSpellCheckWords] = useState([])
    const [disabledTextareaClass, setDisabledTextareaClass] = useState("");
    const [textAreaDisabled, setTextAreaDisabled] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [pointerEventsStatus, setPointerEventsStatus] = useState('all')
    const [editBtnColor, setEditBtnColor] = useState('transparent')
    const [showDropdown, setShowDropdown] = useState(false)
    const [dropdownWords, setDropdownWords] = useState({
        words: ["abc", "bcd"],
        delimiter: ""
    })
    const [dropdownPosition, setDropdownPosition] = useState({
        top: 200,
        left: 200
    })
    const [currentWord, setCurrentWord] = useState("")
    const [progressBarValue, setProgressBarValue] = useState(0)
    const [charCountRow, setCharCountRow] = useState([0, 0, 0])

    // State selector
    const readingSpeed = useSelector(state => state.settingsReducer.readingSpeed)
    const translateLanguage = useSelector(state => state.settingsReducer.translateLanguage)
    const rowLengthValue = useSelector(state => state.settingsReducer.rowLengthValue)
    const nextSubtitleKey = useSelector(state => state.userSettingsReducer.nextSubtitleKey)
    const previousSubtitleKey = useSelector(state => state.userSettingsReducer.previousSubtitleKey)
    const phrases = useSelector(state => state.wordReducer.phrases)
    const dictWords = useSelector(state => state.wordReducer.dictWords)
    const currentProject = useSelector(state => state.currentProjectReducer.project)
    const currentlyTranscribingSubtitle = useSelector(state => state.subtitlePropertiesReducer.currentlyTranscribing)
    const caseStatus = useSelector(state => state.translatedSubtitlesReducer.capsStatus)
    const selectionStart = useSelector(state => state.translatedSubtitlesReducer.selectionStart)
    const selectionEnd = useSelector(state => state.translatedSubtitlesReducer.selectionEnd)



    // Word, char, row counters
    const [wordCount, setWordCount] = useState(0)
    const [charCount, setCharCount] = useState(0)
    const [rowCount, setRowCount] = useState(1)
    const [tempCapital, setTempCapital] = useState(false)

    // Speed, row warnings
    const [speedWarning, setSpeedWarning] = useState(false)
    const [rowWarning, setRowWarning] = useState(false)

    // Use effect states
    const [subtitleText, setSubtitleText] = useState("")
    const [subtitleEnd, setSubtitleEnd] = useState("")
    const [subtitleStart, setSubtitleStart] = useState("")

    // Settings states
    const editFontSize = useSelector(state => state.settingsReducer.editFontSize)
    const autoSave = useSelector(state => state.settingsReducer.autoSave)
    const punctation = useSelector(state => state.settingsReducer.punctation)
    const highlightPhraseStatus = useSelector(state => state.settingsReducer.highlightPhrase)
    const highlightProblematicWordsStatus = useSelector(state => state.settingsReducer.highlightProblematicWords)
    const theme = useSelector(state => state.settingsReducer.theme)
    const keyboardPop = useSelector(state => state.settingsReducer.keyboardPop)

    const getSecondsFromTimestamp = (timestamp) => {
        return new Date(timestamp * 1000).toISOString().substr(11, 12)
    }

    const resetCurrentCommentId = () => {
        dispatch(storeCommentId(null))
    }

    const getWordAt = (str, pos) => {
        str = String(str);
        pos = Number(pos) >>> 0;

        var left = str.slice(0, pos + 1).search(/\S+$/),
            right = str.slice(pos).search(/\s/);
        return [left, right]
    }

    const splitSentence = (subtitle) => {
        setWordCount(subtitle.split(/\s+/).length)
        setCharCount(subtitle.length)
        let newSubtitle = ""
        let changed = 0
        var rows = subtitle.split(/\n/)
        for (var j = 0; j < rows.length; j++) {
            if (rows[j].length > rowLengthValue) {
                let word = ""
                let positions = getWordAt(rows[j], rowLengthValue)
                if (positions[1] < 0) {
                    word = rows[j].slice(positions[0])
                } else {
                    word = rows[j].slice(positions[0], positions[1] + rowLengthValue)
                }
                if (word == '') {
                    let edited = rows[j].substr(0, rowLengthValue) + "\n" + rows[j].substr(rowLengthValue, rows[j].length)
                    rows[j] = edited
                    newSubtitle = rows.join("\n")
                    return splitSentence(newSubtitle)
                } else {
                    let edited = ""
                    edited = rows[j].substr(0, positions[0]) + "\n" + rows[j].substr(positions[0], rows[j].length)
                    rows[j] = edited
                    newSubtitle = rows.join("\n")
                    return splitSentence(newSubtitle)
                }
            } else {
                changed += 1
                if (changed >= rows.length) {
                    newSubtitle = rows.join("\n")
                    return newSubtitle
                }
            }
        }
    }

    const checkSubtitleReadingSpeed = (subtitle) => {
        let preferedNumberOfChars = (subtitleEnd - subtitleStart) * readingSpeed
        setProgressBarValue((subtitle.length / preferedNumberOfChars) * 100)
        if (subtitle.length > preferedNumberOfChars) {
            setSpeedWarning(true)
        } else {
            setSpeedWarning(false)
        }
    }

    const checkSubtitleRows = (subtitle) => {
        var lines = subtitle.split(/\r|\r\n|\n/);
        let newUpdateCount = [lines[0]?.length || 0, lines[1]?.length || 0, lines[2]?.length || 0]
        setCharCountRow(newUpdateCount)
        var count = lines.length;
        setRowCount(count)
        if (count > 2) {
            setRowWarning(true)
        } else {
            setRowWarning(false)
        }
    }

    const getCapitalizedString = (string) => {
        return string.substring(0, 1).toUpperCase() + string.substring(1)
    }

    const checkSubtitleProperties = (subtitle) => {
        checkSubtitleReadingSpeed(subtitle)
        let splited = splitSentence(subtitle)
        setSubtitleText(subtitle)
        checkSubtitleRows(splited)
    }

    const onChangeEvent = (event) => {
        const value = event.target.value;
        const selectionStart = event.target.selectionStart;
        const selectionEnd = event.target.selectionEnd;
        const previousValue = subtitleText

        if ((value.length - previousValue.length === 1)) {
            if (value.length > previousValue.length) {
                const newChar = value.charAt(selectionStart - 1);
                if (newChar.length === 1) {
                    const before = value.slice(0, selectionStart - 1);
                    const after = value.slice(selectionEnd);

                    event.target.value = before + capitalizeByCaseStatus(caseStatus, newChar) + after;

                    const newSelectionStart = selectionStart;
                    event.target.setSelectionRange(newSelectionStart, newSelectionStart);
                }
            }
        }

        setSubtitleText(event.target.value);
        checkSubtitleProperties(event.target.value);
        setLastEditedValue(event.target.value)
        setIsChanged(true);
    }

    const onFocusEvent = (subtitleStart, subtitleId) => {
        if (highlightProblematicWordsStatus) {
            refDiv.current.querySelectorAll('.highlight-textarea').forEach(item => {
                item.addEventListener('dblclick', () => {
                    toggleEditMode()
                })
            })
        }
        dispatch(setLastEdited(subtitleId))
        dispatch(setFirstSentence(""))
        dispatch(setChosenSubtitle({ start: subtitleStart }))
    }

    const blurEvent = (subtitleId, text) => {
        refDiv.current.querySelectorAll('.highlight-textarea').forEach(item => {
            item.removeEventListener('dblclick', () => {
                toggleEditMode()
            })
        })
        let updatedText = text
        if (isChanged) {
            if (punctation) {
                let capitalizedSubtitle = capitalLettersForNamesInPhrases(updatedText, phrases)
                updatedText = capitalizedSubtitle
                setSubtitleText(capitalizedSubtitle)
            }
            dispatch(updateTranslatedSubtitles(subtitleId, updatedText))
            if (autoSave) {
                dispatch(saveProcess("", "", "", currentProject._id))
            }
            dispatch(setCapitalLetter(shouldHaveCapitalLetter(updatedText)))
        }
    }

    const handleSelectionChange = (event) => {
        const start = event.target.selectionStart;
        const end = event.target.selectionEnd;
        dispatch(setSelectionStart(start))
        dispatch(setSelectionEnd(end))
    };

    const clearText = (subtitleId) => {
        setSubtitleText("")
        dispatch(updateTranslatedSubtitles(subtitleId, ""))
        if (autoSave) {
            dispatch(saveProcess("", "", "", currentProject._id))
        }
        dispatch(setLastEditedValue(""))
    }

    const prepareWordsForHighligh = (wordList) => {
        let combined = []
        if (highlightPhraseStatus) {
            let highlightPhrases = phrases.map(phrase => {
                return {
                    highlight: new RegExp(`\\b${phrase}\\b`, 'gi'),
                    className: 'phrase-color-yellow'
                }
            })
            combined = highlightPhrases
        } else {
            combined = []
        }
        if (wordList) {
            combined = combined.concat(wordList.map(w => {
                return {
                    highlight: new RegExp(`\\b${w.word}\\b`, 'gi'),
                    className: 'word-color-lightblue'
                }
            }))
        }
        if (highlightProblematicWordsStatus) {
            let problematicWords = dictWords.map(word => {
                return {
                    highlight: new RegExp(`\\b${word.problem_word}\\b`, 'gi'),
                    className: 'word-color-lightblue'
                }
            })
            combined = combined.concat(problematicWords)
        }
        setHighlightPhrases(combined)
    }

    const checkForShortcuts = (e) => {
        let lastEdited = store.getState().subtitlePropertiesReducer.lastEdited
        if (e.keyCode == nextSubtitleKey && e.ctrlKey == true) {
            e.preventDefault()
            if (numberOfSubtitles > lastEdited) {
                document.getElementsByClassName('highlight-textarea')[lastEdited].focus()
            }
        }
        else if (e.keyCode == previousSubtitleKey && e.ctrlKey == true) {
            e.preventDefault()
            if (lastEdited > 1) {
                document.getElementsByClassName('highlight-textarea')[lastEdited - 2].focus()
            }
        }
    }

    const nextSubtitle = () => {
        let lastEdited = store.getState().subtitlePropertiesReducer.lastEdited
        if (numberOfSubtitles > lastEdited) {
            document.getElementsByClassName('highlight-textarea')[lastEdited].focus()
            dispatch(setFirstSentence(""))
        }
    }

    const previousSubtitle = () => {
        let lastEdited = store.getState().subtitlePropertiesReducer.lastEdited
        if (lastEdited > 0) {
            document.getElementsByClassName('highlight-textarea')[lastEdited - 2].focus()
            dispatch(setFirstSentence(""))
        }
    }

    const toggleEditMode = () => {
        let status = store.getState().settingsReducer.highlightProblematicWords
        if (status) {
            let wrongWords = refDiv.current.querySelectorAll('.word-color-lightblue')
            wrongWords && wrongWords.forEach(item =>
                item.addEventListener('mouseover', (e) => {
                    let posX = e.clientX
                    let posY = e.clientY + 20
                    topPos = posY
                    leftPos = posX
                    setDropdownPosition({
                        top: posY,
                        left: posX
                    })
                    prepareDropdownWords(e.target.outerText)
                    setShowDropdown(true)
                }))
            if (wrongWords.length != 0) {
                editBtnColor == 'transparent' ? setEditBtnColor('red') : setEditBtnColor('transparent')
                pointerEventsStatus == 'all' ? setPointerEventsStatus('none') : setPointerEventsStatus('all')
            }
        }
    }

    const prepareDropdownWords = (word) => {
        let suggestedWords = new Set();
        const suggestedSpelling = spellCheckWords.find(sw => sw.word === word)
        if (suggestedSpelling) {
            suggestedSpelling.suggestedWords.forEach(sw => suggestedWords.add(sw))
        }
        let sugestion = dictWords.find(e => e.problem_word.toLowerCase() == word.toLowerCase())
        if (sugestion?.solution_words) {
            sugestion.solution_words.forEach(sw => suggestedWords.add(sw))
        }
        if ([...suggestedWords].length > 0) {
            setDropdownWords({ words: [...suggestedWords] })
        }
        let capitalState = store.getState().subtitlePropertiesReducer.capitalLetter
        if (capitalState) {
            setTempCapital(true)
        }
        setCurrentWord(word)
    }

    const wordCorrection = (word, subtitleId) => {
        let replaceRegex = new RegExp(`\\b${currentWord}\\b`)
        let replacedText = subtitleText.replace(replaceRegex, word)
        if (tempCapital && replacedText.indexOf(word) == 0) {
            replacedText = replacedText.charAt(0).toUpperCase() + replacedText.slice(1)
        }
        setSubtitleText(replacedText)
        setShowDropdown(false)
        toggleEditMode()
        if (autoSave) {
            dispatch(saveProcess("", "", "", currentProject._id))
        }
    }

    const handleScroll = (event) => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100)
    }

    const onTranslateClick = async () => {
        const data = await dispatch(translateText(uploadedSubText, translateLanguage.code))
        const { translatedText } = data;
        setSubtitleText(translatedText)
        await dispatch(updateTranslatedSubtitles(translatedSubId, translatedText))
        if (autoSave) {
            dispatch(saveProcess("", "", "", currentProject._id))
        }
    }

    const onSpellcheckClick = async () => {
        const data = await dispatch(spellcheck(subtitleText))
        setSpellCheckWords(data.wordList)
        prepareWordsForHighligh(data.wordList)
    }

    useEffect(() => {
        setSubtitleText(translatedSubText)
        setSubtitleStart(translatedSubStart)
        setSubtitleEnd(translatedSubEnd)
        prepareWordsForHighligh()
        // document.addEventListener('scroll', handleScroll)
        // return () => {
        //     document.removeEventListener('scroll', handleScroll)
        // }
    }, [translatedSubText])

    useEffect(() => {
        prepareWordsForHighligh()
    }, [phrases, highlightPhraseStatus, dictWords, highlightProblematicWordsStatus])

    useEffect(() => {
        if (!currentlyTranscribingSubtitle) {
            setDisabledTextareaClass("")
            setTextAreaDisabled(false)
            return
        }
        if (currentlyTranscribingSubtitle === translatedSubId) {
            setDisabledTextareaClass("")
            setTextAreaDisabled(false)
        } else {
            setDisabledTextareaClass("textarea-disabled")
            setTextAreaDisabled(true)
        }
    }, [currentlyTranscribingSubtitle, translatedSubId])


    useEffect(() => {
        checkSubtitleReadingSpeed(subtitleText)
        setWordCount(subtitleText.split(/\s+/).length)
        setCharCount(subtitleText.length)
        let split = splitSentence(subtitleText)
        checkSubtitleRows(split)
    }, [subtitleText])

    return (
        <div className="subtitle-cell-wrapper">
            <div className="subtitle-cell-item translated-subtitles-row" id={translatedSubId} ref={refDiv}>
                <div className="translated-cell-wrapper">
                    {showDropdown &&
                        <div className="dropdown-content" style={{ top: topPos, left: leftPos }} onMouseLeave={() => {
                            setShowDropdown(false)
                            toggleEditMode()
                        }}>
                            {
                                dropdownWords && dropdownWords.words.map(word => {
                                    return <p onClick={() => wordCorrection(word, translatedSubId)}>{word}</p>
                                })
                            }
                        </div>
                    }

                    <div className="subtitle-info-timestamp">

                        <div className="subtitle-info-i subtitle-info-id">
                            {translatedSubId}
                        </div>
                        <div className="subtitle-info-i subtitle-info-start">
                            {getSecondsFromTimestamp(translatedSubStart)}
                        </div>
                        <div className="subtitle-info-i subtitle-info-end">
                            {getSecondsFromTimestamp(translatedSubEnd)}
                        </div>
                        {(!isMobile && !currentProject?.isSnapshot) &&
                            <>
                                <div className="subtitle-info-i subtitle-info-clear">
                                    <div className="icons-st" onClick={() => clearText(translatedSubId)}>
                                        <RiDeleteBin6Line />
                                    </div>
                                </div>
                                <div className="subtitle-info-i subtitle-info-next">
                                    <div className="icons-st" onClick={() => previousSubtitle()}>
                                        <GrLinkPrevious style={{ color: 'white' }} />
                                    </div>
                                </div>
                                <div className="subtitle-info-i subtitle-info-next">
                                    <div className="icons-st" onClick={() => nextSubtitle()}>
                                        <GrLinkNext style={{ color: 'white' }} />
                                    </div>

                                </div>

                                {/* <div className="subtitle-info-i subtitle-info-edit">
                                <BiEdit style={{border: '1px solid black', backgroundColor: editBtnColor}} onClick={() => toggleEditMode()}/>
                            </div> */}
                            </>
                        }
                    </div>
                    {(isMobile && !currentProject?.isSnapshot) &&
                        <div className='mobile-sub-icons'>
                            <div className="subtitle-info-i subtitle-info-clear">
                                <div className="icons-st" onClick={() => clearText(translatedSubId)}>
                                    <RiDeleteBin6Line />
                                </div>
                            </div>
                            <div className="subtitle-info-i subtitle-info-next">
                                <div className="icons-st" onClick={() => previousSubtitle()}>
                                    <GrLinkPrevious style={{ color: 'white' }} />
                                </div>
                            </div>
                            <div className="subtitle-info-i subtitle-info-next">
                                <div className="icons-st" onClick={() => nextSubtitle()}>
                                    <GrLinkNext style={{ color: 'white' }} />
                                </div>

                            </div>
                        </div>
                    }

                    <div onClick={resetCurrentCommentId} className="translated-div-holder" style={{ fontSize: `${editFontSize}px` }}>
                        <ProgressBar className="progress-bar-chars">
                            <ProgressBar now={progressBarValue} variant="danger" />
                            {progressBarValue > 100 ?
                                (<ProgressBar now={0} variant="success" />) :
                                (<ProgressBar now={100 - progressBarValue} variant="success" />)
                            }

                        </ProgressBar>
                        <HighlightWithinTextarea
                            className={"highlight-textarea " + disabledTextareaClass}
                            readOnly={
                                isMobile ? keyboardPop ? false : 'readonly' : false
                            }
                            disabled={(currentProject?.isSnapshot || disabledTextareaClass) ? true : false}
                            value={subtitleText}
                            highlight={highlightPhrases}
                            style={{ backgroundColor: '#27292b' }}
                            onKeyDown={checkForShortcuts}
                            style={{ pointerEvents: pointerEventsStatus, backgroundColor: theme == 'Dark' ? '#121212' : '#ffffff', color: theme == 'Dark' ? '#ffffff' : '#121212' }}
                            onBlur={(input) => blurEvent(translatedSubId, input.target.value)}
                            onFocus={() => onFocusEvent(translatedSubStart, translatedSubId)}
                            onChange={onChangeEvent}
                            translatedSubId={translatedSubId}
                            onSelect={handleSelectionChange}
                            onClick={handleSelectionChange}
                        ></HighlightWithinTextarea>
                    </div>
                    <div className="subtitle-info-counters sic-trans">
                        <span>Reading speed: {readingSpeed}</span> |
                        <span className={speedWarning ? "highlight-red" : ""}> Chars: {charCount}</span> |
                        <span className={rowWarning ? "highlight-red" : ""}> Rows: {rowCount}</span> |
                        <span className='translate-symbol-box' onClick={onTranslateClick}>T</span>
                        <span className='translate-symbol-box' onClick={onSpellcheckClick}>S</span>
                    </div>
                    <SubtitleComment
                        translatedSubId={translatedSubId}
                        subtitleComment={subtitleComment}
                        currentProject={currentProject}
                    />
                </div>
                <div className="count-row-side">
                    <p>{charCountRow[0]}</p>
                    <p>{charCountRow[1]}</p>
                </div>
            </div>
        </div>
    )
})

export default TranslatedSubtitleCell

const SubtitleComment = ({
    translatedSubId,
    subtitleComment,
    currentProject
}) => {
    const dispatch = useDispatch()
    const [comment, setComment] = useState("")
    const autoSave = useSelector(state => state.settingsReducer.autoSave)
    const handleClick = (event) => {
        const id = event.target.getAttribute("data-sub-id");
        dispatch(storeCommentId(id))
        dispatch(setLastEditedCommentValue(event.target.value))
    }

    const textAreaLeave = () => {
        dispatch(addCommentToSubtitle(translatedSubId, comment))
        if (autoSave) {
            dispatch(saveProcess("", "", "", currentProject._id))
        }
    }

    useEffect(() => {
        setComment(subtitleComment)
    }, [])

    return (
        <div>
            <p className="formfield">
                <textarea onClick={handleClick} disabled={currentProject?.isSnapshot ? true : false} tabIndex="-1" placeholder="Enter comment..." id="comment" data-sub-id={translatedSubId} value={comment} className="comment-area comment-trans" onChange={(e) => setComment(e.target.value)} onBlur={textAreaLeave}></textarea>
            </p>
        </div>
    )
}


