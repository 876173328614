import { settingsTypes } from '../actions/types';

const initialState = {
    sourceTitle: false,
    editTitle: false,
    titleFontSize: 17,
    editFontSize: 16,
    rowLengthValue: 42,
    readingSpeed: 17,
    speechToText: false,
    language: {
        lang: "Croatian",
        langCode: "HR-hr"
    },
    translateLanguage: {
        lang: "Croatian",
        code: "hr_HR"
    },
    siteLanguage: "hr",
    languages: [
        {
            id: 1,
            language: "Croatian"
        },
        {
            id: 2,
            language: "English"
        },
        {
            id: 3,
            language: "French"
        },
        {
            id: 4,
            language: "Spanish"
        }
    ],
    translateLanguages: [
        {
            id: 1,
            language: "Croatian"
        },
        {
            id: 2,
            language: "English"
        },
        {
            id: 3,
            language: "French"
        },
        {
            id: 4,
            language: "Spanish"
        }
    ],
    punctation: false,
    spellChecker: false,
    gvtInstant: false,
    pushHold: false,
    autoSave: false,
    highlightPhrase: false,
    highlightProblematicWords: false,
    theme: 'Light',
    keyboardPop: false,
    showSidebar: false,
    autoRecord: false,
    sort: '{"updatedAt": "asc"}',
}

function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case settingsTypes.TOGGLE_SOURCE_TITLE:
            return {
                ...state,
                sourceTitle: action.payload
            }
        case settingsTypes.TOGGLE_EDIT_TITLE:
            return {
                ...state,
                editTitle: action.payload
            }
        case settingsTypes.CHANGE_TITLE_FONT_SIZE:
            return {
                ...state,
                titleFontSize: action.payload
            }
        case settingsTypes.CHANGE_EDIT_FONT_SIZE:
            return {
                ...state,
                editFontSize: action.payload
            }
        case settingsTypes.CHANGE_ROW_LENGTH_VALUE:
            return {
                ...state,
                rowLengthValue: action.payload
            }
        case settingsTypes.CHANGE_READING_SPEED:
            return {
                ...state,
                readingSpeed: action.payload
            }
        case settingsTypes.TOGGLE_SPEECH_TO_TEXT:
            return {
                ...state,
                speechToText: action.payload
            }
        case settingsTypes.CHANGE_LANGUAGE:
            return {
                ...state,
                language: {
                    ...state.language,
                    lang: action.payload.lang,
                    langCode: action.payload.langCode
                }
            }
        case settingsTypes.CHANGE_TRANSLATE_LANGUAGE:
            return {
                ...state,
                translateLanguage: {
                    ...state.language,
                    lang: action.payload.lang,
                    code: action.payload.code
                }
            }
        case settingsTypes.TOGGLE_PUNCTATION:
            return {
                ...state,
                punctation: action.payload
            }
        case settingsTypes.TOGGLE_SPELLCHECKER:
            return {
                ...state,
                spellChecker: action.payload
            }
        case settingsTypes.TOGGLE_GVT_INSTANT:
            return {
                ...state,
                gvtInstant: action.payload
            }
        case settingsTypes.TOGGLE_PUSH_HOLD:
            return {
                ...state,
                pushHold: action.payload
            }
        case settingsTypes.AUTO_SAVE:
            return {
                ...state,
                autoSave: action.payload
            }
        case settingsTypes.HIGHLIGHT_PHRASE:
            return {
                ...state,
                highlightPhrase: action.payload
            }
        case settingsTypes.HIGHLIGHT_PROBLEMATIC_WORDS:
            return {
                ...state,
                highlightProblematicWords: action.payload
            }
        case settingsTypes.CHANGE_THEME:
            return {
                ...state,
                theme: action.payload
            }
        case settingsTypes.KEYBOARD_POP:
            return {
                ...state,
                keyboardPop: action.payload
            }
        case settingsTypes.TOGGLE_SORT:
            return {
                ...state,
                sort: action.payload
            }
        case settingsTypes.TOGGLE_SIDEBAR:
            return {
                ...state,
                showSidebar: action.payload
            }
        case settingsTypes.TOGGLE_AUTO_RECORD:
            return {
                ...state,
                autoRecord: action.payload
            }
        default:
            return state
    }
}

export default settingsReducer;