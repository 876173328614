import axios from 'axios'
import { apiUrl } from '../constants'
import { settingsTypes } from './types'
import Swal from 'sweetalert2'

const DB_SOURCE_TITLE = 'sourceTitle'
const DB_EDIT_TITLE = 'editTitle'
const DB_TITLE_FONT_SIZE = 'titleFontSize'
const DB_ROW_LENGTH = 'rowLength'
const DB_EDIT_FONT_SIZE = 'editFontSize'
const DB_READING_SPEED = 'readingSpeed'
const DB_SPEECH_TO_TEXT = 'speechToText'
const DB_PUNCTATION = 'punctation'
const DB_SPELL_CHECKER = 'spellChecker'
const DB_GVT_INSTANT = 'gvtInstant'
const DB_PUSH_HOLD = 'pushHold'
const DB_AUTO_SAVE = 'autoSave'
const DB_HIGHLIGHT_PHRASE = 'highlightPhrase'
const DB_HIGHLIGHT_PROBLEMATIC_WORDS = 'highlightProblematicWords'
const DB_KEYBOARD_POP = 'keyboardPop'
const DB_AUTO_RECORD = 'autoRecord'
const DB_SORT = 'sort'

const LS_SOURCE_TITLE = 'settings-source-title'
const LS_EDIT_TITLE = 'settings-edit-title'
const LS_SPEECH_TO_TEXT = 'settings-speech-to-text'
const LS_PUNCTATION = 'settings-punctation'
const LS_SPELL_CHECKER = 'settings-spell-checker'
const LS_GVT_INSTANT = 'settings-gvt-instant'
const LS_PUSH_HOLD = 'settings-push-hold'
const LS_AUTO_SAVE = 'settings-auto-save'
const LS_TOGGLE_SIDEBAR = 'settings-toggle-sidebar'
const LS_GVT_LANGUAGE = 'settings-gvt-language';
export const LS_SITE_LANGUAGE = 'settings-site-language';

export const toggleSourceTitle = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_SOURCE_TITLE, status))
    saveToLocalStorage(LS_SOURCE_TITLE, status)
    dispatch({
        type: settingsTypes.TOGGLE_SOURCE_TITLE,
        payload: status
    })
}

export const toggleEditTitle = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_EDIT_TITLE, status))
    saveToLocalStorage(LS_EDIT_TITLE, status)
    dispatch({
        type: settingsTypes.TOGGLE_EDIT_TITLE,
        payload: status
    })
}

export const changeTitleFontSize = (value) => dispatch => {
    dispatch(saveSettingToDatabase(DB_TITLE_FONT_SIZE, value))
    dispatch({
        type: settingsTypes.CHANGE_TITLE_FONT_SIZE,
        payload: value
    })
}

export const changeEditFontSize = (value) => dispatch => {
    dispatch(saveSettingToDatabase(DB_EDIT_FONT_SIZE, value))
    dispatch({
        type: settingsTypes.CHANGE_EDIT_FONT_SIZE,
        payload: value
    })
}

export const changeRowLengthValue = (value) => dispatch => {
    dispatch(saveSettingToDatabase(DB_ROW_LENGTH, value))
    dispatch({
        type: settingsTypes.CHANGE_ROW_LENGTH_VALUE,
        payload: value
    })
}

export const changeReadingSpeed = (value) => dispatch => {
    dispatch(saveSettingToDatabase(DB_READING_SPEED, value))
    dispatch({
        type: settingsTypes.CHANGE_READING_SPEED,
        payload: value
    })
}

export const toggleSpeechToText = (status) => dispatch => {
    saveToLocalStorage(LS_SPEECH_TO_TEXT, status)
    dispatch(saveSettingToDatabase(DB_SPEECH_TO_TEXT, status))
    dispatch({
        type: settingsTypes.TOGGLE_SPEECH_TO_TEXT,
        payload: status
    })
}

export const toggleHighlightPhrase = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_HIGHLIGHT_PHRASE, status))
    dispatch({
        type: settingsTypes.HIGHLIGHT_PHRASE,
        payload: status
    })
}

export const toggleHighlightProblematicWords = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_HIGHLIGHT_PROBLEMATIC_WORDS, status))
    dispatch({
        type: settingsTypes.HIGHLIGHT_PROBLEMATIC_WORDS,
        payload: status
    })
}

export const toggleAutoRecord = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_AUTO_RECORD, status))
    dispatch({
        type: settingsTypes.TOGGLE_AUTO_RECORD,
        payload: status
    })
}

export const toggleSort = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_SORT, status))
    dispatch({
        type: settingsTypes.TOGGLE_SORT,
        payload: status
    })
}

export function changeTranslateLanguage(value) {
    const languageCodes = [
        {
            name: "Croatian",
            code: "hr_HR"
        },
        {
            name: "English",
            code: "en_US"
        },
        {
            name: "French",
            code: "fr_FR"
        },
        {
            name: "Spanish",
            code: "es_ES"
        }
    ]
    let language = languageCodes.find(e => e.name === value)
    let chosenLanguage = {
        lang: language.name,
        code: language.code
    }
    return {
        type: settingsTypes.CHANGE_TRANSLATE_LANGUAGE,
        payload: chosenLanguage
    }
}

export function changeLanguage(value) {
    const languageCodes = [
        {
            name: "Croatian",
            code: "HR-hr"
        },
        {
            name: "English",
            code: "EN-us"
        },
        {
            name: "French",
            code: "FR-fr"
        },
        {
            name: "Spanish",
            code: "ES-es"
        }
    ]
    let language = languageCodes.find(e => e.name === value)
    let chosenLanguage = {
        lang: language.name,
        langCode: language.code
    }

    saveToLocalStorage(LS_GVT_LANGUAGE, value);
    return {
        type: settingsTypes.CHANGE_LANGUAGE,
        payload: chosenLanguage
    }
}

export const changeSiteLanguage = (value) => {
    saveToLocalStorage(LS_SITE_LANGUAGE, value)
    return {
        type: settingsTypes.CHANGE_SITE_LANGUAGE,
        payload: value
    }
}

export const togglePunctation = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_PUNCTATION, status))
    saveToLocalStorage(LS_PUNCTATION, status)
    dispatch({
        type: settingsTypes.TOGGLE_PUNCTATION,
        payload: status
    })
}

export const toggleSpellChecker = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_SPELL_CHECKER, status))
    saveToLocalStorage(LS_SPELL_CHECKER, status)
    dispatch({
        type: settingsTypes.TOGGLE_SPELLCHECKER,
        payload: status
    })
}

export const toggleGvtInstant = (status, toggle = false) => dispatch => {
    if (status && toggle) {
        Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'This feature is in beta testing, it is recommended to keep it switched OFF.',
            showConfirmButton: false,
            timer: 1500
        })
    }
    dispatch(saveSettingToDatabase(DB_GVT_INSTANT, status))
    saveToLocalStorage(LS_GVT_INSTANT, status)
    dispatch({
        type: settingsTypes.TOGGLE_GVT_INSTANT,
        payload: status
    })
}

export const togglePushHold = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_PUSH_HOLD, status))
    saveToLocalStorage(LS_PUSH_HOLD, status)
    dispatch({
        type: settingsTypes.TOGGLE_PUSH_HOLD,
        payload: status
    })
}

export const toggleAutoSave = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_AUTO_SAVE, status))
    saveToLocalStorage(LS_AUTO_SAVE, status)
    dispatch({
        type: settingsTypes.AUTO_SAVE,
        payload: status
    })
}

export const toggleKeyboardPop = (status) => dispatch => {
    dispatch(saveSettingToDatabase(DB_KEYBOARD_POP, status))
    dispatch({
        type: settingsTypes.KEYBOARD_POP,
        payload: status
    })
}

export const toggleSidebar = (status) => dispatch => {
    saveToLocalStorage(LS_TOGGLE_SIDEBAR, status)
    dispatch({
        type: settingsTypes.TOGGLE_SIDEBAR,
        payload: status
    })
}

export const setSettingsFromLocalStorage = () => dispatch => {
    const sourceTitle = localStorage.getItem(LS_SOURCE_TITLE)
    if (sourceTitle) {
        dispatch(toggleSourceTitle(JSON.parse(sourceTitle)))
    }

    const editTitle = localStorage.getItem(LS_EDIT_TITLE)
    if (editTitle) {
        dispatch(toggleEditTitle(JSON.parse(editTitle)))
    }

    const speechToText = localStorage.getItem(LS_SPEECH_TO_TEXT)
    if (speechToText) {
        dispatch(toggleSpeechToText(JSON.parse(speechToText)))
    }

    const punctation = localStorage.getItem(LS_PUNCTATION)
    if (punctation) {
        dispatch(togglePunctation(JSON.parse(punctation)))
    }

    const spellChecker = localStorage.getItem(LS_SPELL_CHECKER)
    if (spellChecker) {
        dispatch(toggleSpellChecker(JSON.parse(spellChecker)))
    }

    const gvtInstant = localStorage.getItem(LS_GVT_INSTANT)
    if (gvtInstant) {
        dispatch(toggleGvtInstant(JSON.parse(gvtInstant)))
    }

    const pushHold = localStorage.getItem(LS_PUSH_HOLD)
    if (pushHold) {
        dispatch(togglePushHold(JSON.parse(pushHold)))
    }

    const autoSave = localStorage.getItem(LS_AUTO_SAVE)
    if (autoSave) {
        dispatch(toggleAutoSave(JSON.parse(autoSave)))
    }

    const gvtLanguage = localStorage.getItem(LS_GVT_LANGUAGE)
    if (gvtLanguage) {
        dispatch(changeLanguage(JSON.parse(gvtLanguage)))
    }

    const siteLanguage = localStorage.getItem(LS_SITE_LANGUAGE)
    if (siteLanguage) {
        dispatch(changeSiteLanguage(JSON.parse(siteLanguage)))
    }
}

export const saveToLocalStorage = (key, value) => {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem(key, jsonValue)
}

export const saveSettingToDatabase = (key, value) => dispatch => {
    axios.post(`${apiUrl}/user-settings/edit_settings`,
        {
            field: key,
            value,
        }
    )
}

export const setSettingsFromDatabase = () => dispatch => {
    axios.post(`${apiUrl}/user-settings/get_settings`)
        .then(res => {
            console.log(res)
            let userSettings = res.data.userSettings
            userSettings.spellChecker && dispatch(toggleSourceTitle(userSettings.spellChecker))
            userSettings.editTitle && dispatch(toggleEditTitle(userSettings.editTitle))
            userSettings.editFontSize && dispatch(changeEditFontSize(userSettings.editFontSize))
            userSettings.rowLength && dispatch(changeRowLengthValue(userSettings.rowLength))
            userSettings.titleFontSize && dispatch(changeTitleFontSize(userSettings.titleFontSize))
            userSettings.readingSpeed && dispatch(changeReadingSpeed(userSettings.readingSpeed))
            userSettings.speechToText && dispatch(toggleSpeechToText(userSettings.speechToText))
            userSettings.punctation && dispatch(togglePunctation(userSettings.punctation))
            userSettings.spellChecker && dispatch(toggleSpellChecker(userSettings.spellChecker))
            userSettings.gvtInstant && dispatch(toggleGvtInstant(userSettings.gvtInstant))
            userSettings.pushHold && dispatch(togglePushHold(userSettings.pushHold))
            userSettings.autoSave && dispatch(toggleAutoSave(userSettings.autoSave))
            userSettings.highlightPhrase && dispatch(toggleHighlightPhrase(userSettings.highlightPhrase))
            userSettings.highlightProblematicWords && dispatch(toggleHighlightProblematicWords(userSettings.highlightProblematicWords))
            userSettings.keyboardPop && dispatch(toggleKeyboardPop(userSettings.keyboardPop))
            userSettings.autoRecord && dispatch(toggleAutoRecord(userSettings.autoRecord))
            userSettings.sort && dispatch(toggleSort(userSettings.sort))
        })
        .catch(err => {
            console.log(err)
        })
}