import { SET_INITIAL_TRANSLATED_SUBTITLES, CHANGE_IMUTABLY, ADD_COMMENT, ADD_COMMENT_UPLOADED, STORE_COMMENT_ID, SET_SELECTION_START, SET_SELECTION_END, SET_CAPS_STATUS } from "./types"

export const setTranslatedSubtitles = (translatedSubtitle) => {
    return {
        type: SET_INITIAL_TRANSLATED_SUBTITLES,
        payload: translatedSubtitle
    }
}

export const updateTranslatedSubtitles = (subtitleId, subtitleText) => {
    return {
        type: CHANGE_IMUTABLY,
        payload: {
            subtitleId,
            subtitleText,
        }
    }
}

export const addCommentToSubtitle = (subtitleId, comment) => {
    return {
        type: ADD_COMMENT,
        payload: {
            subtitleId,
            comment
        }
    }
}

export const storeCommentId = (id) => ({
    type: STORE_COMMENT_ID,
    payload: id,
});

export const setSelectionStart = (value) => {
    return {
        type: SET_SELECTION_START,
        payload: value
    }
}

export const setCapsStatus = (value) => {
    return {
        type: SET_CAPS_STATUS,
        payload: value
    }
}

export const setSelectionEnd = (value) => {
    return {
        type: SET_SELECTION_END,
        payload: value
    }
}