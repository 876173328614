import { ADD_COMMENT, CHANGE_IMUTABLY, SET_INITIAL_TRANSLATED_SUBTITLES, SET_TRANSLATED_SUBTITLES, STORE_COMMENT_ID, SET_SELECTION_START, SET_SELECTION_END, SET_CAPS_STATUS } from "../actions/types"

const initialState = {
    translatedSubtitles: [],
    translatedSubtitlesUncontroled: [],
    commentId: null,
    selectionStart: 0,
    selectionEnd: 0,
    capsStatus: "default"
}

function translatedSubtitlesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INITIAL_TRANSLATED_SUBTITLES:
            return {
                ...state,
                translatedSubtitles: action.payload,
                translatedSubtitlesUncontroled: action.payload
            }
        case CHANGE_IMUTABLY:
            return {
                ...state,
                translatedSubtitles: state.translatedSubtitles.map(
                    (sub, i) => sub.id === action.payload.subtitleId ? { ...sub, text: action.payload.subtitleText }
                        : sub
                )
            }
        case ADD_COMMENT:
            return {
                ...state,
                translatedSubtitles: state.translatedSubtitles.map(
                    (sub, i) => sub.id == action.payload.subtitleId ? { ...sub, comment: action.payload.comment } : sub
                )
            }
        case SET_TRANSLATED_SUBTITLES:
            return {
                ...state,
                translatedSubtitlesUncontroled: action.payload
            }
        case STORE_COMMENT_ID:
            return {
                ...state,
                commentId: action.payload

            }
        case SET_CAPS_STATUS:
            return {
                ...state,
                capsStatus: action.payload
            }
        case SET_SELECTION_START:
            return {
                ...state,
                selectionStart: action.payload
            }
        case SET_SELECTION_END:
            return {
                ...state,
                selectionEnd: action.payload
            }
        default:
            return state
    }
}

export default translatedSubtitlesReducer